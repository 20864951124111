//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "ReleasesSection",
  ssr: false,
  data() {
    return {
      items: [],
      loading: true
    };
  },
  computed: {
    /** @return {ICityExtended} */
    city() {
      return this.$store.state.city.city;
    }
  },
  watch: {
    city() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$axios.$get("/books/releases", {
        params: {
          cityId: this.city.id
        }
      }).then(response => {
        this.loading = false;
        this.items = response.data.map(item => item.bookcrossing.book);
      });
    },
  }
};
