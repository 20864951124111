//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
export default {
  name: "BooksStack",
  props: {
    loading: {
      type: Boolean,
    },
    multiline: {
      type: Boolean,
      default: false
    },
    books: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      isDown: false,
      startX: undefined,
      scrollLeft: undefined,
    }
  },
  methods: {
    onMouseDown(e) {
      if (this.multiline) {
        return;
      }
      this.isDown = true;
      this.startX = e.pageX - this.$refs.list.offsetLeft;
      this.scrollLeft = this.$refs.list.scrollLeft;
    },
    onMouseLeave() {
      if (this.multiline) {
        return;
      }
      this.isDown = false;
    },
    onMouseUp() {
      if (this.multiline) {
        return;
      }
      this.isDown = false;
    },
    onMouseMove(e) {
      if(!this.isDown || this.multiline) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.list.offsetLeft;
      const walk = (x - this.startX) * 3; //scroll-fast
      this.$refs.list.scrollLeft = this.scrollLeft - walk;
      console.log(walk);
    },
    onBookClick(e) {
      if (this.isDown) {
        e.preventDefault()
      }
    }
  }
}
