var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cover",
    class: {
      'cover_loading': _vm.loading
    },
    style: {
      backgroundImage: _vm.gradientString
    }
  }, [_vm.src ? _c('img', {
    staticClass: "cover__image",
    attrs: {
      "src": _vm.src,
      "alt": _vm.title
    }
  }) : _vm._e(), _vm._v(" "), _vm.src ? _c('img', {
    staticClass: "cover__back-image",
    attrs: {
      "src": _vm.src,
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.title && !_vm.src ? _c('span', {
    staticClass: "cover__title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._v(" "), _vm.author && !_vm.src ? _c('span', {
    staticClass: "cover__author"
  }, [_vm._v(_vm._s(_vm.author))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }