//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cover from "../cover";
import { formatTime } from "@/utils/date"

export default {
  name: "FindsSection",
  ssr: false,
  components: { Cover },
  data() {
    return {
      items: [],
      loading: true
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    formatTime,
    async fetchData() {
      this.loading = true;
      await this.$axios.$get("/books/finds").then(response => {
        this.loading = false;
        this.items = response.data;
      });
    },
  }
};
