var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latest-news-block page-section"
  }, [_c('NuxtLink', {
    staticClass: "page-section__title",
    attrs: {
      "to": "/news"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('title')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "latest-news-block__list page-section__content"
  }, [!_vm.loading ? _vm._l(_vm.items, function (news) {
    return _c('NuxtLink', {
      key: news.id,
      staticClass: "news-item card",
      attrs: {
        "to": '/news/' + news.slug
      }
    }, [news.image ? _c('div', {
      staticClass: "news-item__image"
    }, [_c('img', {
      attrs: {
        "src": news.image,
        "loading": "lazy",
        "alt": news.title
      }
    })]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "news-item__content"
    }, [_c('div', {
      staticClass: "news-item__title"
    }, [_vm._v("\n            " + _vm._s(news.title) + "\n          ")]), _vm._v(" "), _c('div', {
      staticClass: "news-item__description"
    }, [_vm._v("\n            " + _vm._s(news.short_text) + "\n          ")])])]);
  }) : _vm._e(), _vm._v(" "), _vm.loading ? _vm._l(2, function (i) {
    return _c('div', {
      key: i,
      staticClass: "news-item card"
    }, [_c('div', {
      staticClass: "news-item__image"
    }), _vm._v(" "), _vm._m(0, true)]);
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news-item__content"
  }, [_c('div', {
    staticClass: "news-item__title-placeholder"
  })]);
}]

export { render, staticRenderFns }