//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LatestNews",
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t("title")
      }
    },
    except: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      items: [],
      loading: true
    };
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true;
      return this.$axios.$get("/news", {
          params: {
            "limit": 2,
            "except": this.except
          }
        }
      ).then(response => {
        this.loading = false;
        this.items = response.news;
      });
    },
  }
};
