//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "StatsSection",
  data() {
    return {
      period: 'year',
      released: 0,
      jailed: 0,
      shelves: 0,

      iterations: 5, // how many times update value
      time: 300, // time to animate in milliseconds
      iteration: 0, // current animation iteration
      timer: null, //timer id
    }
  },
  computed: {
    ...mapState('home', ['statistic']),
    city() {
      return this.$store.state.city.city
    },
  },
  watch: {
    city() {
      this.fetchData();
    },
    period() {
      this.iteration = 0;
      this.startCounting()
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations({
      setData: 'home/SET_PERIOD'
    }),
    ...mapActions('home', ['getCounts']),
    async fetchData() {
      await this.getCounts(this.city.id);
      this.startCounting()
    },
    startCounting() {

      this.timer = setInterval(() => {
        requestAnimationFrame(this.countUp);
        this.iteration++;
        if (this.iteration >= this.iterations) {
          clearInterval(this.timer)
        }
      }, this.time / this.iterations)
    },
    countUp() {
      const releasedCount = this.statistic.released[this.period];
      const jailedCount = this.statistic.jailed[this.period];
      const shelvesCount = this.statistic.shelvesTotal;

      this.released = Math.ceil((releasedCount / this.iterations) * this.iteration);
      this.jailed = Math.ceil((jailedCount / this.iterations) * this.iteration);
      this.shelves = Math.ceil((shelvesCount / this.iterations) * this.iteration);
    }
  },
}
