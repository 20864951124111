var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "intro card"
  }, [_c('div', {
    staticClass: "intro__title"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('title_1'))), _c('br'), _vm._v(_vm._s(_vm.$t('title_2')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "intro__description"
  }, [_vm._v("\n    " + _vm._s(_vm.$t('description_1'))), _c('br'), _vm._v(_vm._s(_vm.$t('description_2')) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "intro__actions"
  }, [!_vm.$auth.loggedIn ? _c('NuxtLink', {
    staticClass: "intro__action button",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('join')) + "\n    ")]) : _c('NuxtLink', {
    staticClass: "intro__action button",
    attrs: {
      "to": "/books/registration"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('add_book')) + "\n    ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "intro__action intro__action_secondary button",
    attrs: {
      "to": "/hunt"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('hunt')) + "\n    ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }