var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "stats page-section"
  }, [_c('div', {
    staticClass: "stats-widget"
  }, [_c('div', {
    staticClass: "stats-widget__selector"
  }, [_c('span', {
    class: {
      'stats-widget-selector__item_selected': _vm.period === 'year'
    },
    on: {
      "click": function ($event) {
        _vm.period = 'year';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('year')))]), _vm._v("\n      /\n      "), _c('span', {
    class: {
      'stats-widget-selector__item_selected': _vm.period === 'month'
    },
    on: {
      "click": function ($event) {
        _vm.period = 'month';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('month')))]), _vm._v("\n      /\n      "), _c('span', {
    class: {
      'stats-widget-selector__item_selected': _vm.period === 'day'
    },
    on: {
      "click": function ($event) {
        _vm.period = 'day';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('today')))])]), _vm._v(" "), _c('div', {
    staticClass: "items"
  }, [_c('div', {
    staticClass: "stats-widget__item"
  }, [_c('div', {
    staticClass: "stats-widget__count"
  }, [_vm._v("\n          " + _vm._s(_vm._f("numberFormat")(_vm.released)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__description"
  }, [_vm._v("\n          " + _vm._s(_vm.$tc('released', _vm.released)) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__item"
  }, [_c('div', {
    staticClass: "stats-widget__count"
  }, [_vm._v("\n          " + _vm._s(_vm._f("numberFormat")(_vm.jailed)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__description"
  }, [_vm._v("\n          " + _vm._s(_vm.$tc('jailed', _vm.jailed)) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__item"
  }, [_c('div', {
    staticClass: "stats-widget__count"
  }, [_vm._v("\n          " + _vm._s(_vm._f("numberFormat")(_vm.shelves)) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__description"
  }, [_vm._v("\n          " + _vm._s(_vm.$tc('shelves', _vm.shelves)) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__item"
  }, [_c('div', {
    staticClass: "stats-widget__count"
  }, [_vm._v("\n          200K+\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__description"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('books')) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__item"
  }, [_c('div', {
    staticClass: "stats-widget__count"
  }, [_vm._v("\n          50K+\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "stats-widget__description"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('users')) + "\n        ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }