//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BookCover",
  props: {
    title: {
      type: String, default: "",
    },
    color: {
      type: String, default: "#f9c933",
    },
    author: {
      type: String, default: "",
    },
    loading: {
      type: Boolean, default: false,
    },
    src: {
      type: String,
      default: null,
    },
  },
  computed: {
    gradientString() {
      return "linear-gradient(125deg," + this.color + " -112%, transparent 92%)"
    },
  },
}
