var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about page-section"
  }, [_c('h2', {
    staticClass: "page-section__title"
  }, [_c('nuxt-link', {
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('what')) + "\n    ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "page-section__content"
  }, [_c('h3', [_vm._v("\n      " + _vm._s(_vm.$t('subtitle')) + "\n    ")]), _vm._v(" "), _c('p', [_vm._v("\n      " + _vm._s(_vm.$t('description')) + "\n    ")]), _vm._v(" "), _c('p', [_vm._v("\n      " + _vm._s(_vm.$t('how')) + "\n    ")]), _vm._v(" "), _c('div', [_c('ul', [_c('li', [_vm._v("— " + _vm._s(_vm.$t('step1')))]), _vm._v(" "), _c('li', [_vm._v("— " + _vm._s(_vm.$t('step2')))]), _vm._v(" "), _c('li', [_vm._v("— " + _vm._s(_vm.$t('step3')))]), _vm._v(" "), _c('li', [_vm._v("— " + _vm._s(_vm.$t('step4')))])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }