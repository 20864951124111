//
//
//
//
//
//
//
//
//
//
//

import stats from '~/components/index/stats'
import releases from '~/components/index/releases'
import latestNews from '~/components/index/latestNews'
import Finds from "components/index/finds";
import getMeta from "@/utils/getMeta"
import AboutBlock from "components/index/about"
import IntroBlock from "components/index/intro"
export default {
  name: 'IndexPage',
  components: {
    IntroBlock,
    AboutBlock,
    Finds,
    stats,
    releases,
    latestNews,
  },
  head() {
    return getMeta({
      title: 'Сайт буккроссинга в России bookcrossing.ru',
      description: 'Официальный сайт буккроссинга в России. Буккроссинг, ' +
      'раздача книг или книговорот — хобби и общественное движение, ' +
      'действующее по принципу социальных сетей и близкое к флешмобу'
    })
  }
}
