import { render, staticRenderFns } from "./booksStack.vue?vue&type=template&id=21c8ce44&scoped=true&"
import script from "./booksStack.vue?vue&type=script&lang=js&"
export * from "./booksStack.vue?vue&type=script&lang=js&"
import style0 from "./booksStack.vue?vue&type=style&index=0&id=21c8ce44&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c8ce44",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cover: require('/builds/bookcrossing/front/components/cover.vue').default})
