import formatDistance from "date-fns/formatDistance"
import parseISO from "date-fns/parseISO"
import en from "date-fns/locale/en-US"
import ru from "date-fns/locale/ru"
import { differenceInYears, isSameDay, isSameYear } from "date-fns"
import { format } from "date-fns-tz"

export const formatDateTime = (timeString, locale = "ru") => {
  const dateTime = parseISO(timeString)
  return format(
    dateTime,
    "d MMMM yyyy в HH:mm",
    {
      locale: locale === "ru" ? ru : en,
    },
  )
}

export const formatTime = (timeString, locale = "ru") => {
  if (!timeString) return ""

  const dateTime = parseISO(timeString)

  if (differenceInYears(new Date(), dateTime) > 1) {
    return formatDateTime(timeString, locale)
  }

  return formatDistance(
    dateTime,
    new Date(),
    {
      addSuffix: true,
      locale: locale === "ru" ? ru : en,
    },
  )
}

export const formatShortTime = (timeString, locale = "ru") => {
  const dateTime = parseISO(timeString)
  let dateFormat = "d MMM yyyy"

  if (isSameDay(dateTime, new Date())) {
    dateFormat = "HH:mm";
  }

  if (isSameYear(dateTime, new Date())) {
    dateFormat = "d MMM";
  }

  return format(
    dateTime,
    dateFormat,
    {
      locale: locale === "ru" ? ru : en,
    },
  )
}
