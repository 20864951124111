var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "list",
    staticClass: "books-stack",
    class: {
      'books-stack_multiline': _vm.multiline,
      'books-stack_active': _vm.isDown
    },
    on: {
      "mousedown": _vm.onMouseDown,
      "mouseleave": _vm.onMouseLeave,
      "mouseup": _vm.onMouseUp,
      "mousemove": _vm.onMouseMove
    }
  }, [!_vm.loading ? _vm._l(_vm.books, function (book) {
    var _book$author;

    return _c('div', {
      key: book.id,
      staticClass: "books-stack-item"
    }, [_c('NuxtLink', {
      attrs: {
        "to": '/books/' + book.id
      },
      on: {
        "click": _vm.onBookClick
      }
    }, [_vm._t("info", null, {
      "book": book
    }), _vm._v(" "), _c('cover', {
      attrs: {
        "id": book.id,
        "color": book.color,
        "title": book.title,
        "author": book === null || book === void 0 ? void 0 : (_book$author = book.author) === null || _book$author === void 0 ? void 0 : _book$author.shortName,
        "src": book.cover
      }
    })], 2), _vm._v(" "), _c('NuxtLink', {
      attrs: {
        "to": '/books/' + book.id
      }
    }, [_c('div', {
      staticClass: "books-stack-item__title"
    }, [_vm._v("\n          " + _vm._s(book.title) + "\n        ")]), _vm._v(" "), book.author ? _c('div', {
      staticClass: "books-stack-item__author"
    }, [_vm._v("\n          " + _vm._s(book.author.shortName) + "\n        ")]) : _vm._e()])], 1);
  }) : _vm._e(), _vm._v(" "), _vm.loading ? _vm._l(10, function (i) {
    return _c('div', {
      key: i,
      staticClass: "books-stack-item"
    }, [_c('cover', {
      attrs: {
        "loading": ""
      }
    })], 1);
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }