var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "releases-widget page-section"
  }, [_c('div', {
    staticClass: "releases-widget__title page-section__title"
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/hunt"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('title')) + "\n      "), _vm.city ? [_vm._v("\n        " + _vm._s(_vm.$t('in_city', {
    city: _vm.$i18n.locale === 'en' ? _vm.city.title_en : _vm.city.title_in
  })) + "\n      ")] : _vm._e()], 2)], 1), _vm._v(" "), _c('div', {
    staticClass: "releases-widget__list page-section__content"
  }, [_c('books-stack', {
    attrs: {
      "books": _vm.items,
      "loading": _vm.loading
    }
  }), _vm._v(" "), !_vm.loading && !_vm.items.length ? _c('p', [_vm._v("\n      В вашем городе пока нет книг\n    ")]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }