var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "finds page-section"
  }, [_c('div', {
    staticClass: "finds__title page-section__title"
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/hunt"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('title')) + "\n    ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "finds__list page-section__content"
  }, [!_vm.loading ? _vm._l(_vm.items, function (item) {
    var _item$bookcrossing$bo, _item$bookcrossing$bo2;

    return _c('div', {
      key: item.bookcrossing.book.id,
      staticClass: "finds-item"
    }, [_c('NuxtLink', {
      attrs: {
        "to": '/books/' + item.bookcrossing.book.id
      }
    }, [_c('div', {
      staticClass: "finds-item__meta"
    }, [item.city ? [_vm._v("\n              " + _vm._s(_vm.$i18n.locale === 'en' ? item.city.title_en : item.city.title) + ","), _c('br'), _vm._v(" " + _vm._s(_vm.formatTime(item.date)) + "\n            ")] : _vm._e()], 2), _vm._v(" "), _c('cover', {
      attrs: {
        "id": item.bookcrossing.book.id,
        "color": item.bookcrossing.book.color,
        "title": item.bookcrossing.book.title,
        "author": (_item$bookcrossing$bo = item.bookcrossing.book) === null || _item$bookcrossing$bo === void 0 ? void 0 : (_item$bookcrossing$bo2 = _item$bookcrossing$bo.author) === null || _item$bookcrossing$bo2 === void 0 ? void 0 : _item$bookcrossing$bo2.shortName,
        "src": item.bookcrossing.book.cover
      }
    })], 1), _vm._v(" "), _c('NuxtLink', {
      attrs: {
        "to": '/books/' + item.bookcrossing.book.id
      }
    }, [_c('div', {
      staticClass: "finds-item__title"
    }, [_vm._v("\n            " + _vm._s(item.bookcrossing.book.title) + "\n          ")]), _vm._v(" "), item.bookcrossing.book.author ? _c('div', {
      staticClass: "finds-item__author"
    }, [_vm._v("\n            " + _vm._s(item.bookcrossing.book.author.shortName) + "\n          ")]) : _vm._e()])], 1);
  }) : _vm._e(), _vm._v(" "), _vm.loading ? _vm._l(10, function (i) {
    return _c('div', {
      key: i,
      staticClass: "finds-item"
    }, [_c('cover', {
      attrs: {
        "loading": ""
      }
    })], 1);
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }