var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('intro-block'), _vm._v(" "), _c('stats'), _vm._v(" "), _c('about-block'), _vm._v(" "), _c('releases'), _vm._v(" "), _c('finds'), _vm._v(" "), _c('latestNews')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }